import React, { Component } from 'react';
import sortBy from 'lodash/sortBy';
import clsx from 'clsx';

import Icon, { CircleIcon } from '../icon/icon';
import PolicyIcon from '../PolicyIcon';
import IconButton from '../icon-button/icon-button';

import './_tree.scss';

export default class Tree extends Component {
  getInitialState() {
    return {
      parent: null,
    };
  }

  componentWillMount() {
    this.updateParent(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateParent(nextProps);
  }

  updateParent(props) {
    if (!props.current) {
      return;
    }

    let parent = null;

    if (!props.flatten) {
      let defaultNode = props.data.getDefault ? props.data.getDefault() : null;

      if (props.current.parent && props.data.contains(props.current)) {
        parent = props.current.parent;
      } else if (defaultNode && defaultNode.isRoot) {
        parent = defaultNode;
      }
    }

    this.setState({
      parent: parent,
    });
  }

  render(props, state) {
    return (
      <div
        className={clsx(
          'tree',
          props.type && `tree--${props.type}`,
          props.noWrap && `tree--nowrap`,
          props.inline && 'tree--inline'
        )}
      >
        {this.renderRootNode(props, state)}
        {this.renderList(props, state)}
      </div>
    );
  }

  renderRootNode(props, state) {
    if (!state.parent) {
      return;
    }

    let classes = ['tree__root__select'];
    let back;

    if (!state.parent.isRoot) {
      back = <IconButton icon="chevron" handleClick={this.handleClickBack} />;

      classes.push('tree__root__select--parent');
    }

    return (
      <div className="tree__item tree__root">
        <button
          class={classes.join(' ')}
          type="button"
          aria-selected={props.current && props.current === state.parent ? true : null}
          disabled={props.invalid && props.invalid.indexOf(state.parent) !== -1 ? true : null}
          data-disabled={props.exclude && props.exclude.indexOf(state.parent) !== -1 ? true : null}
          onClick={this.handleClickItem.bind(this, state.parent)}
        >
          {state.parent.model.name}
        </button>
        {back}
      </div>
    );
  }

  renderList(props, state) {
    let nodes;

    if (props.flatten || !state.parent) {
      nodes = props.reverse ? props.data.collection.slice(0).reverse() : props.data.collection;
    } else {
      nodes = sortBy(state.parent.children, ['model.name']);
    }

    if (!nodes.length && props.emptyMessage) {
      return (
        <ul className="tree__list">
          <li className="tree__item">
            <button className="tree__item__select" type="button" disabled={true}>
              {props.emptyMessage}
            </button>
          </li>
        </ul>
      );
    }

    return (
      <ul className="tree__list">
        {nodes.map(function (node) {
          return (
            <>
              {this.renderListItem(node, props)}
              {!props.hideChildren && props.inline && node.children && node.children.length
                ? node.children.map(function (child) {
                    return this.renderListItem(child, props);
                  }, this)
                : null}
            </>
          );
        }, this)}
      </ul>
    );
  }

  renderListItem(node, props) {
    let label, button, count;

    let classNames = ['tree__item__select'];

    let depth = 0;

    // When searching and tree is flattened nodes are stubs so switch for originals
    if (node.node) {
      label = node.label;
      depth = node.depth;

      node = node.node;
    }

    let parent = node.children && node.children.length;

    if (!props.flatten && parent) {
      if (!props.inline) {
        button = (
          <IconButton icon="chevron" handleClick={this.handleClickChildren.bind(this, node)} />
        );
      }

      classNames.push('tree__item__select--parent');
    }

    if (props.showCounts && node.children && node.children.length) {
      count = ` (${node.children.length})`;
    }

    return (
      <li className="tree__item">
        <button
          type="button"
          class={classNames.join(' ')}
          data-depth={props.flatten && depth ? depth : null}
          aria-selected={props.current.model === node.model ? true : null}
          disabled={props.invalid && props.invalid.indexOf(node) !== -1 ? true : null}
          data-disabled={props.exclude && props.exclude.indexOf(node) !== -1 ? true : null}
          onClick={this.handleClickItem.bind(this, node)}
        >
          {!parent && props.showIcons ? <PolicyIcon options={node.model.icon} /> : null}
          <span
            dangerouslySetInnerHTML={{ __html: (label || node.model.name) + (count || '') }}
          ></span>
        </button>
        {button}
      </li>
    );
  }

  handleClickBack = (event) => {
    this.setState({
      parent: this.state.parent.parent ? this.state.parent.parent : null,
    });
  };

  handleClickItem(node) {
    this.props.handleClick(node);
  }

  handleClickChildren(node) {
    this.setState({ parent: node });
  }
}
