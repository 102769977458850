import React from 'react';
import clsx from 'clsx';

import './styles.scss';

export function DashboardColumns({ columns = 3, children }) {
  return (
    <div className={clsx('dashboard-columns', `dashboard-columns--n${columns}`)}>{children}</div>
  );
}

export function DashboardColumnsFooter({ children }) {
  return <div className="dashboard-columns-footer">{children}</div>;
}

export function DashboardColumnFooter({ children }) {
  return <div className="dashboard-column-footer">{children}</div>;
}

export function DashboardColumnHeader({ modifier, children }) {
  return (
    <div
      className={clsx(
        'dashboard-column-header',
        modifier && `dashboard-column-header--${modifier}`
      )}
    >
      {children}
    </div>
  );
}

export function DashboardColumnBody({ children }) {
  return <div className="dashboard-column-body">{children}</div>;
}
