import React, { Component } from 'react';

import './_loader.scss';

export default class Loader extends Component {
    render (props) {
        let className = 'loader';

        if (props.transparent) {
            className += ' loader--transparent';
        }

        return (
            <div
                class={ className }
                style={{ display: props.visible ? 'block' : 'none' }}
            >
                <div className="loader__icon" />
            </div>
        );
    }
}

export function WrappedLoader (props) {
    return (
        <div className="loader__wrapper">
            <Loader { ...props } />
        </div>
    );
}
